import React from "react";
import Helmet from "react-helmet";

import { imageObject } from "./seoHelpers";

const SchemaContactPage = ({ title, description, image, url }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "ContactPage",
            "name": "${title}",
            "description": "${description}",
            "image": ${imageObject(image)},
            "url": "${url}"
          }
        `}</script>
    </Helmet>
  );
};

export default SchemaContactPage;
