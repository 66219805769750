import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import "../styles/pages/clinic.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

const Clinic = ({ data }) => {
  const { page } = data;
  return (
    <div className="clinic">
      <Seo page={page} />
      <Layout>
        <div className="max-width page-title">
          <h1>{data.page.data.title.text}</h1>
        </div>
        {data.page.data.body[0].items.map(element => (
          <div className="max-width image-with-text" key={element.id}>
            <div className="image">
              <GatsbyImage
                image={element.image.gatsbyImageData}
                style={{ borderRadius: 8 }}
              />
            </div>
            <div className="text">
              <PrismicRichText field={element.content.richText} />
            </div>
          </div>
        ))}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query clinicPageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicClinic(lang: { eq: $lang }, id: { eq: $id }) {
      id
      url
      type
      data {
        meta_title
        meta_description
        title {
          text
        }
        body {
          ... on PrismicClinicDataBodyImageWithContent {
            id
            items {
              content {
                richText
              }
              image {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default Clinic;
